import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'



const getDefaultState = () => {
    return {
        avatar: {
            face: {
                sex: null,
                hair: null,
                hairColor: null,
                eyesColor: null,
                mouth: null,
                extra: null,
            },
            story: null,
            activeStoryId: null,
            goals: {
                wage: null,
                travel: null,
            }
        },
        cv: {
            steps: {
                1: {
                    cards: [],
                    selected: null,
                },
                2: {
                    cards: [],
                    selected: null,
                },
                3: {
                    cards: [],
                    selected: null,
                },
                4: {
                    cards: [],
                    selected: null,
                },
                5: {
                    cards: [],
                    selected: null,
                },
                6: {
                    cards: [],
                    selected: null,
                },
                7: {
                    cards: [],
                    selected: null,
                },
            }
        },
        job: {
            favorites: [],
            selected: [],
            welcomePopupOpen: true,

            /*
            STATUS:
            badCv
            badQualification
            missingPhoto
            ok
            accept
            acceptEmploymentDepartment
            badInterview
            goodInterview
            */
        },
        email: {
            activeJob: null,
        },
        interview: {
            questions: [],
            activeQuestion: -1,
            finished: false,
        },
        contract: {
            modules: [],
        },
        quiz: {
            questions: [],
            activeQuestion: 0,
        },
        progress: {
            general: null,
            /*
            GENERAL PROGRESS:
            profile
            cv
            portal
            email
                employmentDepartment
                employmentDepartmentEmail
            interview
            gameOver
            contract
            satisfaction
            quiz
            score
            feedback
            */


            profile: {
                current: 1,
                historic: 1,
            },
            cv: {
                current: 1,
                historic: 1,
            },
            employmentDepartment: false,
            firstInterview: true,
            interview: {
                jobId: null,
                goodAnswerCount: 0,
                badAnswerCount: 0,
            },
            quiz: {
                goodAnswerCount: 0,
                badAnswerCount: 0,
            },
            finalJob: null,
            satisfaction: null,
            quickMode: false,
        },
        data: {
            stories: [],
            fields: [],
            jobs: [],
            jobsCategories: [],
            districts: [],
            companies: [],
            options: [],
            interview: [],
            contract: [],
            quiz: [],
        },
        country: '',
    }
}



export default createStore({

    state: getDefaultState,

    getters: {
        state(state) {
            console.log(state)
        },
        fieldNameById: (state) => (fieldId) => {
            const object = state.data.fields.find(field => field.id === fieldId)
            return object.title
        },
        avatarSex(state) {
            return state.avatar.face.sex.name
        },
        avatarFullName(state) {
            var name = state.avatar.story.firstName + ' ' + state.avatar.story.lastName

            if (state.avatar.story.degree.length !== "" && state.avatar.story.degree == "DiS.") {
                var name = name + ' ' + state.avatar.story.degree
            } else if(state.avatar.story.degree.length !== "") {
                var name = state.avatar.story.degree + ' ' + name
            }

            return name
        },
        selectedCvCardCorrectness: (state) => (step) => {
            const selected = state.cv.steps[step].selected

            //ochrana proti errorům při klikání životopisu (faq modal dělal problémy)
            if(selected === null) return

            const object = state.cv.steps[step].cards[selected]
            return object.correct
        },
        selectedCvCardPhotoCorrectness: (state) => (step, id) => {
            const selected = state.cv.steps[step].selected

            //ochrana proti errorům při klikání životopisu (faq modal dělal problémy)
            if(selected === null) return

            //dvě volby, které jsou vždy špatně
            var correctness = (selected === "swimming" || selected === "selfie") ? false : true

            //výjimka pro úpravu fotky, když inzerát fotku požaduje a není zadaná
            if (id != null && id != undefined) { //varianta pro odesílání reakcí na inzerát
                const jobWithoutPhoto = state.job.selected.filter(val => val.status[val.status.length - 1] === "missingPhoto" && val.id === id)
                if (jobWithoutPhoto.length > 0 && selected === "noPhoto") {
                    correctness = false
                }
            } else { //varianta pro opravu životopisu
                const jobsWithoutPhoto = state.job.selected.filter(val => val.status[val.status.length - 1] === "missingPhoto")
                if (jobsWithoutPhoto.length > 0 && selected === "noPhoto") {
                    correctness = false
                }
            }

            return correctness
        },
        maxWage(state) {
            return Math.max.apply(Math, state.data.jobs.map(function(o){return o.wage;}))
        },
        getCompany: (state) => (id) => {
            const company = state.data.companies.find(val => val.id == id)
            return company
        },
        getDistrict: (state) => (id) => {
            const district = state.data.districts.find(val => val.id == id)
            return district
        },
        countFavoritesJobs(state) {
            return (state.job.favorites.length > 0) ? state.job.favorites.length : 0
        },
        countRemainingSelectedJobs(state) {
            const max = 4
            return max - state.job.selected.length
        },
        findFavoriteJob: (state) => (id) => {
            const index = state.job.favorites.findIndex((obj => obj.id === id))
            return state.job.favorites[index]
        },
        findJob: (state) => (id) => {
            const job = state.data.jobs.find(obj => obj.id === id)
            return job
        },
        cvScoreValidation: (state, getters) => (id) => { //úspěšnost vyplnění životopisu
            var score = 0
            //karty
            for (let i = 1; i < 7; i++) {
                if(getters.selectedCvCardCorrectness(i)) score += 5
            }
            //fotky
            if(!getters.selectedCvCardPhotoCorrectness(7, id)) score -= 5

            return (score < 30) ? false : true
        },
        getJobQualificationValidation: (state, getters) => (id) => { //validace kvalifikace avatara

            var status = false
            var job = getters.findJob(id)

            //má avatar vzdělání v daném oboru?
            var isField = false
            state.avatar.story.education.forEach( val => {
                if(isField === false && job.fields.includes(val.fieldId)) {
                    isField = true
                }
            })

            //délka praxe obecně
            var experienceLength = state.avatar.story.experienceLength

            //má praxi v oboru? (níže se pak určuje, jestli se bere v potaz)
            var isExperience = false
            state.avatar.story.experience.forEach( val => {
                if(isExperience === false && job.needExperienceInFieldList.includes(val.fieldId)) {
                    isExperience = true
                }
            })


            /*
            Vztah požadavků na obor a praxi
                A = splněné vzdělání + splněná minimální požadovaná praxe
                    Pokud je Podmínka praxe v oboru --> délka praxe musí být v daném oboru
                NEBO = splněné vzdělání v oboru NEBO délka praxe (popř. v daném oboru)

            */

            //podmínky pro vztah mezi vzděláním a oborem
            if (job.realationshipEducationExperience === "A") { //splněno vzdělání v oboru A délka praxe
                if (isField && job.minExperience <= experienceLength) {
                    status = true
                }
            } else {//splněno vzdělání v oboru NEBO délka praxe
                if (isField || job.minExperience <= experienceLength) {
                    status = true
                }
            }

            //je potřebná praxe v oboru?
            if (job.needExperienceInField === "ANO") {
                if (!isExperience) {
                    status = false
                }
            }

            return status
        },
        getJobSkillsValidation: (state, getters) => (id) => { //úspěšnost dovedností
            var countRequested = 0
            var countReal = 0
            var job = getters.findJob(id)

            //jazyky
            if (job.skills.languages) {
                var allRequestedSkillsExist = true
                job.skills.languages.forEach(val => {
                    //pokud má avatar skill s dostatečnou úrovní, přičti 1 (pro výpočet %)
                    countRequested += 1
                    const skill = state.avatar.story.skills.languages.find(lang => lang.name === val.name)
                    if (skill && skill.level.value >= val.level.value) countReal += 1

                    //pokud avatar NEMÁ POŽADOVANÝ skill s dostatečnou úrovní --> false
                    if (val.required === true && !(skill && skill.level.value >= val.level.value) && allRequestedSkillsExist) allRequestedSkillsExist = false
                })
                if(allRequestedSkillsExist === false) return false
            }

            //pc
            if (job.skills.computers) {
                var allRequestedSkillsExist = true
                job.skills.computers.forEach(val => {
                    //pokud má avatar skill s dostatečnou úrovní, přičti 1 (pro výpočet %)
                    countRequested += 1
                    const skill = state.avatar.story.skills.computers.find(comp => comp.name === val.name)
                    if (skill && skill.level.value >= val.level.value) countReal += 1

                    //pokud avatar NEMÁ POŽADOVANÝ skill s dostatečnou úrovní --> false
                    if (val.required === true && !(skill && skill.level.value >= val.level.value) && allRequestedSkillsExist) allRequestedSkillsExist = false
                })
                if(allRequestedSkillsExist === false) return false
            }

            //řidičák
            if (job.skills.car) {
                var allRequestedSkillsExist = true
                job.skills.car.forEach(val => {
                    //pokud má avatar skill, přičti 1 (pro výpočet %)
                    countRequested += 1
                    const skill = state.avatar.story.skills.car.find(car => car === val)
                    if (skill) countReal += 1

                    //pokud nemá všechny řidičáky --> false
                    if (job.skills.car_required === true && !skill && allRequestedSkillsExist) allRequestedSkillsExist = false
                })
                if(allRequestedSkillsExist === false) return false
            }

            //ostatní
            if (job.skills.other) {
                var allRequestedSkillsExist = true
                job.skills.other.forEach(val => {
                    //pokud nemá všechny ostatní požadované dovednosti --> false
                    const skill = state.avatar.story.skills.other.find(other => other.value === val.value)
                    if (!skill && allRequestedSkillsExist) allRequestedSkillsExist = false
                })
                if(allRequestedSkillsExist === false) return false
            }

            //výpočet score, pokud existuje nějaká dovednost bez required
            if (countRequested > 0) {
                var score = (countReal === 0) ? 0 : countReal / countRequested * 100
                if(score < 49) return false
            }

            //pokud projde vším --> splněno
            return true
        },
    },
    mutations: {

        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },


        UPDATE_COUNTRY(state, data) {
            this.state.country = data;
        },

        UPDATE_AVATAR_FACE(state, data) {
            this.state.avatar.face = data;
        },

        UPDATE_AVATAR_STORY(state, data) {
            this.state.avatar.story = data;
        },

        UPDATE_AVATAR_STORY_ID(state, data) {
            this.state.avatar.activeStoryId = data;
        },

        UPDATE_PROFILE_PROGRESS(state, data) {
            //0 - integer
            //1 - current/historic
            //3 - true/false (pro přeskočení podmínky níže)
            if (data[1] === "historic" && data[0] < this.state.progress.profile.historic && (typeof data[2] === 'undefined')) { return }
            this.state.progress.profile[data[1]] = data[0];
        },

        UPDATE_GENERAL_PROGRESS(state, data) {
            this.state.progress.general = data;
        },

        UPDATE_GOALS(state, data) {
            this.state.avatar.goals[data[1]] = data[0];
        },

        UPDATE_DATA(state, data) {
            this.state.data[data[1]] = data[0];
        },

        UPDATE_QUICKMODE(state, data) {
            this.state.progress.quickMode = data;
        },

        //cv
        UPDATE_CV_PROGRESS(state, data) {
            if (data[1] === "historic" && data[0] < this.state.progress.cv.historic) { return }
            this.state.progress.cv[data[1]] = data[0];
        },
        UPDATE_CV_STEPS_CARDS(state, data) {
            this.state.cv.steps[data[1]].cards = data[0];
        },
        CV_SELECT_CARD(state, data) {
            this.state.cv.steps[data[1]].selected = data[0];
        },
        CV_UPDATE_OPTIONS(state, data) {
            this.state.data.options[data[1]] = data[0];
        },

        //portal
        JOB_CLOSE_POPUP(state) {
            state.job.welcomePopupOpen = false
        },
        JOB_ADD_FAVORITE(state, data) {
            this.state.job.favorites.push(data)
        },
        JOB_REMOVE_FAVORITE(state, data) {
            const items = this.state.job.favorites.filter(val => val.id != data)
            this.state.job.favorites = items
        },
        JOB_UPDATE_FAVORITE(state, data) {
            const index = this.state.job.favorites.findIndex((obj => obj.id === data.id))
            this.state.job.favorites[index].status = data.status
        },
        JOB_ADD_SELECTED(state, data) {
            this.state.job.selected.push(data)
        },

        JOB_UPDATE_SELECTED(state, data) {
            const index = this.state.job.selected.findIndex((obj => obj.id === data.id))
            this.state.job.selected[index].status.push(data.status)
            if(data.unread) this.state.job.selected[index].unread = data.unread
        },

        //email
        EMAIL_UPDATE_ACTIVEJOB(state, id) {
            this.state.email.activeJob = id
        },
        EMAIL_UNREAD_FALSE(state, id) {
            const index = this.state.job.selected.findIndex((obj => obj.id === id))
            this.state.job.selected[index].unread = false
        },
        SET_EMPLOYMENT_DEPARTMENT_TRUE(state) {
            state.progress.employmentDepartment = true
        },

        //interview
        SET_FIRST_INTERVIEW_FALSE(state) {
            state.progress.firstInterview = false
        },
        SET_INTERVIEW_JOB_ID(state, id) {
            state.progress.interview.jobId = id
        },
        SET_INTERVIEW_QUESTIONS(state, questions) {
            state.interview.questions = questions
        },
        CLEAR_INTERVIEW_QUESTIONS(state) {
            state.interview.questions = []
        },
        INCREMENT_INTERVIEW_ACTIVE_QUESTION(state) {
            state.interview.activeQuestion += 1
        },
        SET_INTERVIEW_MYANSWER(state, int) {
            state.interview.questions[state.interview.activeQuestion].myAnswer = int
        },
        UPDATE_INTERVIEW_FINISHED(state, bool) {
            state.interview.finished = bool
        },
        INCREMENT_INTERVIEW_ANSWERS(state, data) {
            state.progress.interview[data[0]] += data[1]
        },
        RESET_INTERVIEW(state) {
            state.interview.activeQuestion = -1
            state.interview.questions.forEach(element => {
                delete element.myAnswer
            });
        },



        SET_FINAL_JOB(state, int) {
            state.progress.finalJob = int
        },

        //smlouva
        ADD_CONTRACT_MODULES(state, obj) {
            state.contract.modules = obj
        },
        SET_CONTRACT_MODULES_ANSWER(state, data) {
            state.contract.modules[data[0]].myAnswer = data[1]
        },


        SET_SATISFACTION(state, int) {
            state.progress.satisfaction = int
        },


        //životní situace
        SET_QUIZ_QUESTIONS(state, questions) {
            state.quiz.questions = questions
        },
        CLEAR_QUIZ_QUESTIONS(state) {
            state.quiz.questions = []
        },
        INCREMENT_QUIZ_ACTIVE_QUESTION(state) {
            state.quiz.activeQuestion += 1
        },
        RESET_QUIZ_ACTIVE_QUESTION(state) {
            state.quiz.activeQuestion = 0
        },
        SET_QUIZ_MYANSWER(state, int) {
            state.quiz.questions[state.quiz.activeQuestion].myAnswer = int
        },
        SET_QUIZ_MYANSWER_CORRECTNESS(state, bool) {
            state.quiz.questions[state.quiz.activeQuestion].myAnswerCorrectness = bool
        },
        INCREMENT_QUIZ_ANSWERS(state, data) {
            state.progress.quiz[data[0]] += data[1]
        },


    },

    actions: {
        resetState ({ commit }) {
            commit('RESET_STATE')
        },
    },
    modules:
    {
    },

    plugins: [new VuexPersistence().plugin]

})
