<template>

    <div
        class="block md:inline-block text-sm lg:text-base leading-none text-center uppercase font-display rounded-50 cursor-pointer select-none transition-all"
        :class="[
            color === 'blue' ? '!text-sm text-white bg-blue py-3.5 px-8 shadow-button-blue hover:shadow-button-blue-active' : 'text-green-dark bg-yellow py-3 lg:py-4 px-6 lg:px-10 shadow-button hover:shadow-button-active',
            {'!text-[20px] lg:!text-[24px] lg:!leading-10 !px-16 lg:!px-24 !py-4 lg:!py-5': size === 'big' && color === 'yellow'},
            {'lg:!text-[18px] lg:!leading-8 lg:!px-16 lg:!py-3.5': size === 'big' && color === 'blue'},
        ]"
    >{{title}}</div>

</template>

<script>

export default {

    props: {
        title: {
            type: String,
        },
        size: {
            type: String,
            default: "normal",
        },
        color: {
            type: String,
            default: "yellow"
        }
    },

}

</script>

<style lang="scss" scoped>

.disabled {
    @apply pointer-events-none shadow-none bg-gray-light text-white;
}

/* .blue {
    @apply text-white bg-blue text-sm py-3.5 px-8 shadow-button-blue hover:shadow-button-blue-active;
} */

</style>
