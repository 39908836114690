<template>

    <transition v-show="open" name="modalContent">
        <div
            class="fixed -translate-x-1/2 left-1/2 top-8 lg:top-[80px] w-full z-50 overflow-auto bg-white shadow-modal overscroll-contain"
            :class="[
                {'max-w-[697px]' :  type === 'cv'},
                {'max-w-[1007px]' :  type === 'basic' || type === 'job' || type === 'welcomeJob'},
                height === 'auto' ? 'min-h-[300px]' : 'bottom-8 xl:bottom-[60px] 2xl:bottom-[134px]',
            ]"
        >
            <div
                class="relative z-20"
                :class="{
                    'pl-4 lg:pl-16 pr-0 lg:pr-10 py-14 lg:py-28': type === 'basic' || type === 'job',
                    'pl-4 lg:pl-16 pr-0 lg:pr-10 py-14 lg:pb-16 lg:pt-8': type === 'welcomeJob',
                    'pl-4 lg:pl-14 pr-0 lg:pr-8 pt-14': type === 'cv',
                }"
            >

                <div class="absolute right-6 top-6 cursor-pointer hover:-rotate-90 transition" @click="close">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.4676 0.550626C22.7577 -0.159271 21.6109 -0.159271 20.901 0.550626L12 9.43345L3.09898 0.532423C2.38908 -0.177474 1.24232 -0.177474 0.532423 0.532423C-0.177474 1.24232 -0.177474 2.38908 0.532423 3.09898L9.43345 12L0.532423 20.901C-0.177474 21.6109 -0.177474 22.7577 0.532423 23.4676C1.24232 24.1775 2.38908 24.1775 3.09898 23.4676L12 14.5666L20.901 23.4676C21.6109 24.1775 22.7577 24.1775 23.4676 23.4676C24.1775 22.7577 24.1775 21.6109 23.4676 20.901L14.5666 12L23.4676 3.09898C24.1593 2.40728 24.1593 1.24232 23.4676 0.550626Z" fill="#18AAA1"/>
                    </svg>
                </div>

                <div class="h-full overflow-y-auto pr-6">
                    <h3 class="h3 text-green-dark text-center mb-12"
                    :class="{
                        'hidden': showHeading === false,
                    }">
                        <slot name="heading">
                        </slot>
                    </h3>

                    <div class="entry-content">
                        <slot name="body">
                        </slot>
                    </div>
                </div>
            </div>
        </div>

    </transition>

    <transition v-show="open" name="modalBg">
        <div class="fixed left-0 right-0 top-0 bottom-0 bg-white/50 z-40" @click="close"></div>
    </transition>

</template>



<script>

export default {

    props: {
        open: {
            default: false,
        },
        type: {
            default: 'basic',
        },
        height: {
            default: 'full',
        },
        showHeading: {
            default: true,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },

        shadowShow() {
            return (this.open === true && this.type === 'cv') ? true : false
        },

    },
    emits: ['close'],
}

</script>



<style lang="scss" scoped>

.modal-body-max-h {
    max-height: calc(100% - 88px);
}


.modalContent-enter-from,.modalContent-leave-to {
    @apply opacity-0 scale-75;
}
.modalContent-enter-to, .modalContent-leave-from {
    @apply opacity-100;
}
.modalContent-enter-active, .modalContent-leave-active {
    @apply transition-all duration-500;
}

.modalBg-enter-from,.modalBg-leave-to {
    @apply opacity-0;
}
.modalBg-enter-to, .modalBg-leave-from {
    @apply opacity-100;
}
.modalBg-enter-active, .modalBg-leave-active {
    @apply transition-all duration-300;
}

</style>
