import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundComponent from '@/views/404Page.vue';

const routes = [

  //úvod
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
        title: 'Cesta k zaměstnání'
    },
  },


  //modul 1 - profil
  {
    path: '/profil-uvod',
    name: 'profil-uvod',
    component: () => import('../views/1profileIntroView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Profil'
    },
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import('../views/1profileView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Profil'
    },
  },

  //modul 2 - životopis
  {
    path: '/zivotopis-uvod',
    name: 'zivotopis-uvod',
    component: () => import('../views/2cvIntroView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Životopis'
    },
  },
  {
    path: '/zivotopis',
    name: 'zivotopis',
    component: () => import('../views/2cvView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Životopis'
    },
  },

  //modul 3 - pracovní portál
  {
    path: '/portal-uvod',
    name: 'portal-uvod',
    component: () => import('../views/3portalIntroView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Pracovní portál'
    },
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import('../views/3portalView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Pracovní portál'
    },
  },

  //modul 4 - email
  {
    path: '/email',
    name: 'email',
    component: () => import('../views/4emailView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | E-mail'
    },
  },
  {
    path: '/urad',
    name: 'urad',
    component: () => import('../views/4employmentDepartmentView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Úřad práce'
    },
  },

  //modul 5 - pohovor
  {
    path: '/pohovor-uvod',
    name: 'pohovor-uvod',
    component: () => import('../views/5interviewIntroView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Pohovor'
    },
  },
  {
    path: '/pohovor',
    name: 'pohovor',
    component: () => import('../views/5interviewView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Pohovor'
    },
  },

  //modul 6 - smlouva
  {
    path: '/smlouva-uvod',
    name: 'smlouva-uvod',
    component: () => import('../views/6contractIntroView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Smlouva'
    },
  },
  {
    path: '/smlouva',
    name: 'smlouva',
    component: () => import('../views/6contractView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Smlouva'
    },
  },

  //modul 8 - životní situace
  //P.S. modul 7 neexistuje --> původně se mělo jednat o návrat zpět do emailu, ale to je ošetřeno v modulu 4
  {
    path: '/situace-uvod',
    name: 'situace-uvod',
    component: () => import('../views/8quizIntroView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Životní situace'
    },
  },
  {
    path: '/situace',
    name: 'situace',
    component: () => import('../views/8quizView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Životní situace'
    },
  },

  //modul 9 - vyhodnocení
  {
    path: '/vyhodnoceni-uvod',
    name: 'vyhodnoceni-uvod',
    component: () => import('../views/9scoreIntroView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Vyhodnocení'
    },
  },
  {
    path: '/vyhodnoceni',
    name: 'vyhodnoceni',
    component: () => import('../views/9scoreView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Vyhodnocení'
    },
  },

  //modul 10 - dotazník
  {
    path: '/dotaznik',
    name: 'dotaznik',
    component: () => import('../views/10feedbackView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Dotazník'
    },
  },

  //vyhodncoení spokojenosti po smlouvě
  {
    path: '/spokojenost',
    name: 'spokojenost',
    component: () => import('../views/satisfactionView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Vyhodnocení spokojenosti'
    },
  },

  //game over - veřejně prospěšné práce
  {
    path: '/verejne-prospesne-prace',
    name: 'verejne-prospesne-prace',
    component: () => import('../views/gameOverView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Veřejně prospěšné práce'
    },
  },

  //děkovná obrazovka
  {
    path: '/dekujeme',
    name: 'dekujeme',
    component: () => import('../views/thankYouView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Děkujeme!'
    },
  },

  //generovací obrazovka (pro hraní částí)
  {
    path: '/generovani',
    name: 'generovani',
    component: () => import('../views/generatorView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Generování postavy'
    },
  },
  //ukončovací obrazovka (pro hraní částí)
  {
    path: '/ukonceni',
    name: 'ukonceni',
    component: () => import('../views/finalPartView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Ukončení'
    },
  },

  //přístupnost
  {
    path: '/pristupnost',
    name: 'pristupnost',
    component: () => import('../views/accessibilityView.vue'),
    meta: {
        title: 'Cesta k zaměstnání | Prohlášení o přístupnosti'
    },
  },






  { path: '/:pathMatch(.*)*', component: NotFoundComponent}, //404

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
})


export default router
