<template>

    <modalPopup
        :open="open"
        @close="close()"
    >
        <template v-slot:heading>
            Tipy pro kontrolu životopisu
        </template>
        <template v-slot:body>

            <div class="entry-content">

                <div
                    v-if="mode === 'normal'"
                    class="text-center -translate-y-6"
                >
                    <p>
                        <a class="underline hover:no-underline" href="https://europa.eu/europass/eportfolio/screen/cv-editor?lang=cs">Šablona pro tvorbu životopisu Europass</a><br>
                        <a class="underline hover:no-underline" href="/files/zivotopis_vzor.png">Vzor životopisu ke stažení</a><br>
                    </p>
                </div>

                <h2
                    v-if="mode === 'faq'"
                    class="h5 text-green-dark !mb-8"
                >Zaměř se na tyto oblasti:</h2>

                <div
                    v-for="(content, index) in contents" :key="index"
                    class="mb-8 last:mb-0"
                    v-show="mode === 'normal' || !validate(index + 1)"
                >

                    <div
                        class="label-lg transition-all"
                        :class="[
                            mode === 'faq' ? 'pl-8 relative cursor-pointer select-none' : '',
                            mode === 'faq' && content.opened ? 'pb-4' : 'pb-0',
                            mode === 'normal' ? '!pb-4' : ''
                        ]"
                        @click="mode === 'faq' ? content.opened = !content.opened : ''"
                    >
                        <svg
                            v-if="mode === 'faq'"
                            class="absolute top-1 left-0 transition"
                            :class="content.opened ? '' : '-rotate-90'"
                            width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0158 5.43564L9.10216 12.0299C8.67791 12.4346 7.99006 12.4346 7.5658 12.0299L0.652175 5.43564C0.22792 5.03098 0.227921 4.3749 0.652175 3.97024C1.07643 3.56558 1.76428 3.56558 2.18854 3.97024L8.33398 9.83183L14.4794 3.97024C14.9037 3.56558 15.5915 3.56558 16.0158 3.97024C16.44 4.3749 16.44 5.03098 16.0158 5.43564Z" fill="#18AAA1"/>
                        </svg>

                        <span>{{content.name}}</span>
                    </div>

                    <div
                        v-for="(item, index2) in content.items" :key="index2"
                        class="mb-4 last:mb-0 transtion-all"
                        :class="mode === 'normal' ? 'block' : (content.opened ? 'block' : 'hidden')"
                    >
                        <h6
                            v-if="item.name"
                            class="h6 text-green-dark mb-2"
                        >{{item.name}}</h6>
                        <p v-if="item.html" v-html="item.html"></p>
                    </div>

                </div>


                <div v-if="mode === 'normal'">

                    <p class="label-lg mt-8">Teď pár obecných rad pro tvorbu CV:</p>
                    <h6 class="h6 text-green-dark mb-2">Zkontroluj pořadí informací</h6>
                    <p>Životopis má ustálenou podobu, na kterou jsou všichni zvyklí. Snaž se toho držet, ať personalistům usnadníš hledání.</p>
                    <h6 class="h6 text-green-dark mb-2">Pozor na formát</h6>
                    <p>Životopis musí být přehledný. Nepříjemná velikost písma nebo chybějící odstavce kazí dojem a hlavně chuť do čtení.</p>
                    <h6 class="h6 text-green-dark mb-2">Nepřeháněj to s kreativitou</h6>
                    <p>Krasopisné fonty a obrázky koťátek sem opravdu nepatří. Samozřejmě pokud se nehlásíš na něco uměleckého.</p>
                    <h6 class="h6 text-green-dark mb-2">Aktualizuj</h6>
                    <p>Životopis aktualizuj. 5 let starý životopis za tebe říká hlavně to, že o tu práci zase tolik nestojíš.</p>
                    <h6 class="h6 text-green-dark mb-2">Životopis si po sobě třikrát přečti</h6>
                    <p>A pečlivě hledej chyby, překlepy, slang a jakékoliv nevhodné výrazy. Můžeš ho dát i na kontrolu někomu jinému. Víc očí víc vidí.</p>
                    <h6 class="h6 text-green-dark mb-2">Nepřeháněj to s informacemi</h6>
                    <p>Určitě je důležité povědět, co umíš. Ale zároveň nechceš vypadat jako rozumbrada ani jako náfuka. Vyber to důležité a servíruj to stručně. Životopis by měl mít maximálně 2 strany.</p>

                    <p class="label-lg mt-8">A zlatý tip na závěr:</p>
                    <p>Každý je v životopisu motivovaný, aktivní, ambiciózní a tak dál. To nikoho neohromí. Zkus to upřímně a vynech tahle prázdná slova. Pokud na tebe některé z nich opravdu 100% sedí, dobře, ale napiš to raději jinak. Nechceš přece zapadnout mezi všechny ty ostatní motivované. Mimochodem, upřímnost je skvělá. Nejen proto, že je to tak správné. Ale proto, že si díky ní najdeš přesně tu práci, kam se budeš hodit a kde ti bude dobře.</p>

                </div>

            </div>

        </template>
    </modalPopup>


</template>


<script>

import modalPopup from '@/components/modalPopup.vue';

export default {

    components: {
        modalPopup,
    },

    props: {
        open: {
            default: false,
        },
        files: {
            default: false,
        },
        mode: {
            default: "normal",
        },
    },

    data() {
        return {
            contents: [
                {
                    name: 'Kontaktní údaje',
                    opened: false,
                    items: [
                        {
                            name: 'Uveď celé své jméno',
                            html: 'Na přezdívky a zkratky zapomeň. V životopise je potřeba být seriózní a uvést celé oficiální jméno i příjmení. Ano, i pokud je nudné a přezdívka tě vyjadřuje lépe.'
                        },
                        {
                            name: 'Správné telefonní číslo',
                            html: 'Raději dvakrát zkontrolovat. Přece chceš, aby se ti dovolali. Oni se zase tak moc snažit nebudou. Když jim nedáš správný telefon, nejspíš zavolají jinému uchazeči.'
                        },
                        {
                            name: 'Profesionální e-mail',
                            html: 'Na hledání práce se e-mail typu blond_kote a crazy_killer nehodí. Udělej si takový, kde bude jen tvé jméno a příjmení. Doplň ho o čísla a znaky, pokud je tvé jméno už obsazené. Rozumná čísla. '
                        },
                        {
                            name: 'Adresa',
                            html: 'Do životopisu už nemusíš psát přesnou adresu. Je to na tobě. Pokud už budeš nějakou adresu uvádět, tak použij kontaktní adresu, tedy tu, kde jsi bez problému k zastižení. Není nutné dávat za každou cenu adresu trvalého bydliště. Nebo jen uveď okres (např. Ostrava).'
                        },
                        {
                            name: '',
                            html: '<i>Až budeš tvořit vlastní životopis, tak do kontaktních údajů můžeš přidat i odkaz na tvůj LinkedIn nebo web s&nbsp;ukázkami práce.</i>'
                        },
                    ]
                },
                {
                    name: 'Pracovní zkušenosti',
                    opened: false,
                    items: [
                        {
                            name: 'Začni od nejaktuálnějších pracovních zkušeností a přidej dostatek informací',
                            html: 'Jméno firmy, název pozice, náplň tvé práce a od kdy do kdy jsi tam pracoval/a. Tohle tam určitě musíš vždy mít. Nepřeháněj to s detaily, ale zároveň pověz, co díky té práci dokážeš. Protože o to jde.',
                        }
                    ]
                },
                {
                    name: 'Vzdělání',
                    opened: false,
                    items: [
                        {
                            name: 'Začni od nejvyššího dosaženého a přidej dostatek informací',
                            html: 'Určitě vždy musíš napsat název školy, obor a od kdy do kdy jsi tam chodil/a, případně i zakončení (např. titul). Piš jen to, co je důležité. Hlavně žádná mateřská škola.',
                        }
                    ]
                },
                {
                    name: 'Osobní dovednosti',
                    opened: false,
                    items: [
                        {
                            name: 'U cizích jazyků doplň svou úroveň znalostí',
                            html: 'Používá se společný evropský referenční rámec, který obsahuje 6 jazykových úrovni – od A1 (začátečník) po C2 (rodilý mluvčí). ',
                        },
                        {
                            name: 'U počítačových znalostí uveď svojí úroveň',
                            html: 'Základní až pokročilou. Bez uvedení nikdo nebude vědět, jak moc daný program ovládáš.',
                        },
                        {
                            name: 'U řidičského průkazu uveď zase skupinu',
                            html: 'Např. sk B. Můžeš i dopsat, zda jsi aktivní řidič a kolik km ročně najedeš.',
                        },
                    ]
                },
                {
                    name: 'Kurzy',
                    opened: false,
                    items: [
                        {
                            name: '',
                            html: 'Začni od nejaktuálnějších kurzů a vždy uveď přesný název kurzu, krátce se můžeš rozepsat o náplni kurzu, dále uveď instituci, u které jsi kurz absolvoval/a a můžeš doplnit, jestli jsi získal certifikát.',
                        },
                        {
                            name: '',
                            html: 'Hlavně nepiš o všem, hlavně pokud toho máš za sebou víc. Dětské kurzy plné her nikoho nezajímají. Teda pokud nehledáš místo lektora dětských kurzů. (Pro každého jsou důležité jiné dovednosti a kurzy. Životopis vždy přizpůsob pozici, o kterou se ucházíš.). Není vhodné uvádět kurzy staré 5 a více let.',
                        },
                    ]
                },
                {
                    name: 'Zájmy',
                    opened: false,
                    items: [
                        {
                            name: '',
                            html: 'Není nutné je uvádět, ale mohou přidat více osobitosti do životopisu. Pokud už nějaké vybereš, <b>tak se kroť</b>. Není potřeba napsat úplně všechno, co tě baví. Vyber pár zájmů, které o tobě něco vypovídají. Pokud jich máš hodně, vyber ty, které se týkají pracovních dovedností, jsou neobvyklé, chytré, zajímavé. Záliby jako párty nebo střílení mimozemšťanů ve videohrách si raději nech jako příjemné překvapení do budoucna.',
                        }
                    ]
                },
                {
                    name: 'Fotka',
                    opened: false,
                    items: [
                        {
                            name: '',
                            html: 'Není povinná, ale může tě odlišit od jiných životopisů a přidat více osobitosti do tvého životopisu. Navíc někteří zaměstnavatelé ji vyloženě chtějí. Dej si ale pozor na výběr fotky – fotka v plavkách na pláži není dobrý nápad. Ani pokud ti to tam vážně sluší. Stejně tak se nehodí fotky z akcí, povedené selfie z koupelny a tmavé rozpixlované fotky z dávné minulosti. Fotka tě reprezentuje. Chceš vypadat upraveně, mile, zodpovědně. A že ti místo stálo za tu námahu udělat si kvůli němu jedno reprezentativní foto.',
                        }
                    ]
                },
            ]
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },

        validate(step) {
            if (step < 7) {
                var correctness = this.$store.getters.selectedCvCardCorrectness(step)
            } else {
                var correctness = this.$store.getters.selectedCvCardPhotoCorrectness(step)
            }
            return correctness
        },
    },

}

</script>
