<template>

    <modalPopup
        :open="open"
        @close="close()"
    >
        <template v-slot:heading>
            Rady a tipy pro úspěšný pohovor
        </template>

        <template v-slot:body>

            <div class="entry-content">
                <p>Jak se říká, štěstí přeje připraveným a u pohovorů to platí na 100 %. Tady najdeš pár tipů, které ti pomohou při reálném pracovním pohovoru. Některé z nich využiješ i v naší hře.</p>

                <p class="label-lg mt-8">Před pohovorem</p>
                <h6 class="h6 text-green-dark mb-2">Zjisti:</h6>
                <ul>
                    <li>
                        <strong>Kde firma sídlí a jak se tam jede</strong><br>
                        Doraz 5–10 minut před schůzkou. Přijít pozdě je absolutní průšvih, ale příliš brzo se také nehodí (pokud chceš větší rezervu, vždy můžeš přijet dřív a chvíli relaxovat na lavičce v parku – moudrá volba!).
                    </li>
                    <li>
                        <strong>Co nejvíc informací o firmě</strong><br>
                        Její historii, zaměření, ocenění, významné klienty, partnery atd. Čím víc víš, tím větší šanci máš zapůsobit a dokázat, že to myslíš vážně.
                    </li>
                    <li>
                        <strong>Víc o formě výběrového řízení</strong><br>
                        Ne na každém pohovoru se jen mluví. Zjisti, co tě čeká, ať se můžeš dobře připravit. Může to být:
                        <ul>
                            <li><strong>Běžný rozhovor</strong></li>
                            <li>
                                <strong>Online rozhovor</strong><br>
                                Forma pohovoru na dálku přes webkameru, v poslední době stále oblíbenější řešení. Připrav se jako na fyzický pohovor, vhodně se obleč a ber to vážně. Je dobré se s programem, přes který bude pohovor, seznámit v dostatečném předstihu před schůzkou, aby tě nezaskočily technické komplikace. Taky si většinou můžeš v programu nastavit neutrální pozadí, pokud za sebou nemáš prostor vhodný pro pohovor.
                            </li>
                            <li>
                                <strong>Přezkoušení znalostí</strong><br>
                                Můžou tě čekat i individuální nebo skupinové testy. Běžné jsou matematické nebo verbální testy, testy inteligence, psychologické testy, test znalostí práce na PC apod.
                            </li>
                            <li>
                                <strong>Assessment centrum</strong><br>
                                Speciální metoda, která slouží pro výběr zaměstnanců z většího počtu uchazečů. Využívá se především pro manažerské, obchodní a často nebo opakovaně obsazované pozice ve firmě. Je obvykle náročnější než běžný pohovor a budeš muset předvést své schopnosti v akci (hlavní zásada zní: hlavně se projev a zapoj!).
                            </li>
                        </ul>
                    </li>
                </ul>

                <h6 class="h6 text-green-dark mb-2 mt-6">Znovu si přečti inzerát i svůj životopis</h6>
                <ul>
                    <li><strong>Zamysli se, co můžeš firmě nabídnout.</strong> Představ si otázky, které by ti mohli dát, a promysli své odpovědi. Často na pohovoru uslyšíš například:
                    <ul>
                        <li>Proč se ucházíte o práci právě u nás? (Rozhodně nemluv o tom, kde všude už tě odmítli a že teď vezmeš práci kdekoliv. Taky není důvod prozrazovat, co všechno ti nejde. Naopak najdi důvody, proč je tahle práce přesně pro tebe).</li>
                        <li>Co očekáváte od tohoto zaměstnání?</li>
                        <li>Jaká byla vaše pracovní náplň a zodpovědnosti v posledním zaměstnání?</li>
                        <li>Jaké jsou vaše silné stránky? Jaké jsou vaše slabé stránky?</li>
                        <li>Pracujete raději v kolektivu nebo sám?</li>
                        <li>Jak vypadal váš typický pracovní den?</li>
                        <li>Co vidíte jako svůj největší přínos pro naši společnost?</li>
                        <li>Proč bychom měli vybrat na tuto pozici právě vás?</li>
                        <li>Jak vaše vzdělání koresponduje s požadavky na pozici?</li>
                        <li>Jaké si představujete finanční ohodnocení?</li>
                        <li>Kdy můžete případně nastoupit?</li>
                    </ul>

                    </li>
                </ul>

                <h6 class="h6 text-green-dark mb-2 mt-6">Vhodně se oblékni</h6>
                <ul>
                    <li>Lidé dají na první dojem. Určitě se proto oblékni čistě a slušně, s ohledem na firmu a pozici, na kterou se hlásíš. Tepláky nebo plážové žabky nejsou správnou volbou.</li>
                </ul>

                <p class="label-lg mt-8">Během pohovoru</p>
                <ul>
                    <li><strong>Nervozitou se netrap</strong>, nervózní je skoro každý a náboráři to chápou. Ale i tak si dej <strong>pozor na slušné a příjemné chování</strong>. Nutným základem je například pozdravit, ztlumit si telefon, zbavit se žvýkačky, neskákat do řeči atd.</li>
                    <li><strong>Pozor na řeč těla</strong>, bude velkou částí dojmu, který uděláš. Hlavně žádná leklá ryba ani drtička při podání ruky. Seď vzpřímeně, s nohama u sebe. Pozor i na hlas – mluv dostatečně hlasitě.</li>
                    <li><strong>Nabídka pití během pohovoru</strong> může být zrádná. Klidně ji přijmi, ale pozor na výběr nápoje. Čaj a káva budou na začátku horké, a jestli jsi nervózní a máš rozechvělé ruce, je tu riziko polití nebo problémů se sáčky, cukrem a podobně. Minerálka s bublinkami zase může vést ke krkání. Čistá voda je sázka na jistotu.</li>
                    <li>Během pohovoru <strong>pozorně naslouchej</strong> a snaž se odpovídat zajímavěji než jen „ano“ a „ne“ (ale mluv k věci). Klidně si vezmi i blok a tužku a dělej si poznámky a ptej se na to, co tě zajímá.</li>
                    <li>Až dojde řeč na minulou práci, šéfa a kolegy, <strong>vyhni se velké kritice a pomluvám</strong>. Ale určitě odpovídej upřímně a nic si nevymýšlej.</li>
                    <li><strong>Otázka na tvá finanční očekávání</strong> se většinou objevuje ke konci pohovoru a měl by ji první vyslovit personalista. S otázkou počítej a měj připravenou co nejkonkrétnější odpověď.</li>
                </ul>
                <p class="mt-6">Během pohovoru se tě personalista může zeptat, jestli máš nějaké otázky. Je dobré si alespoň jeden dva dotazy připravit, protože jinak to může působit tak, že o firmu nemáš velký zájem. Můžeš se ptát třeba na:</p>
                <ul>
                    <li>Jedná se o nově vytvořenou pozici?</li>
                    <li>Pokud ne, z jakého důvodu se pozice uvolnila?</li>
                    <li>Jaká jsou klíčová kritéria úspěšné práce na této pozici?</li>
                    <li>S jakým kolegou nebo kolegy budu spolupracovat nejvíce?</li>
                    <li>Nabízíte možnost pracovat částečně z domova nebo pružnou pracovní dobu?</li>
                    <li>Vyžadujete v práci dresscode (určitý styl oblékání zaměstnanců)?</li>
                    <li>Nabízíte nějaké benefity? </li>
                </ul>
                <p class="mt-6"><i>Mezi benefity často patří:</i></p>
                <ul class="italic">
                    <li>Homeoffice – možnost práce z domova.</li>
                    <li>Sickdays – obykle 3–5 dní v roce, které můžeš využít a zůstat doma, když ti nebude dobře. Potvrzení od lékaře v tomhle případě nepotřebuješ. Jedná se o zdravotní volno nad rámec dovolené.</li>
                    <li>Systém Kafeterie (Cafeterie) – zaměstnancům se připisují body podle stanovených podmínek. Své body pak můžeš využít podle svého uvážení. Od příspěvků na zájezd, přes lístek do divadla, masáž či nákup v lékárně.</li>
                    <li>Dovolená navíc, příspěvky na kulturní a sportovní akce, vzdělávání, příspěvek na penzijní připojištění a životní pojištění, stravenky, dlužební automobil či služební telefon pro soukromé účely, dárky zaměstnancům, teambuildingy (firemní akce na sblížení kolektivu), firemní večírky atd.</li>
                </ul>

                <p class="label-lg mt-8">Po pohovoru</p>
                <ul>
                    <li>Zamysli se nad tím, jak na tebe působili lidé na pohovoru a jak se ti líbila celá firma. Chceš na takovém místě pracovat? Hodně ti napoví už jen prostředí firmy.</li>
                    <li>Výsledek se většinou dozvíš během týdne, maximálně dvou. Pokud byl pohovor úspěšný, můžou tě čekat ještě další výběrová kola.</li>
                </ul>

                <p class="label-lg mt-8">Malý tip na závěr</p>
                <p>Sebedůvěra je skvělá věc, ale o namyšlené chytráky většinou nikdo nestojí. Stejně tak ani přehnaná skromnost a sebekritika ti nepomůžou. Nejdál dojdeš se slušným chováním a s rozumně dávkovanou upřímností.</p>
                <p>Hodně štěstí!</p>

            </div>

        </template>
    </modalPopup>

</template>


<script>

import modalPopup from '@/components/modalPopup.vue';

export default {

    components: {
        modalPopup,
    },

    props: {
        open: {
            default: false,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },

}

</script>
