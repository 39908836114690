import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import mitt from 'mitt'
import store from './store'

const app = createApp(App);
const emitter = mitt()

app.config.globalProperties.emitter = emitter;

app.use(router)
app.use(store)

app.mount('#app')

