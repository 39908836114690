//načtení dat z WP

import axios from 'axios'

export default {
    methods: {
        //načtení dat z WP
        getApiFields() {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/field.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'fields'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },

        getApiStories(prefix) {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/'+prefix+'profile.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'stories'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },

        getApiJobs(prefix) {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/'+prefix+'job.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'jobs'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },

        getApiJobsCategories() {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/jobcategory.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'jobsCategories'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },

        getApiOptions(prefix) {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/'+prefix+'options.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'options'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },

        getApiDistricts(prefix) {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/'+prefix+'district.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'districts'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },

        getApiCompanies(prefix) {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/'+prefix+'company.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'companies'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },

        getApiInterview() {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/interview.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'interview'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },

        getApiContract() {
            axios.get('https://cestakzamestnani.cz/administrace/wp-content/uploads/json_data/contract.json')
            .then(response => {
                this.$store.commit('UPDATE_DATA', [response.data, 'contract'])
            })
            .catch(function (error) {
                alert("Došlo k chybě pravděpodobně způsobené nedostupností serveru nebo špatným internetovým připojením. Zkuste obnovit stránku.")
                console.log(error)
            })
        },


        loadAllData(country) {

            country = country || "msk"

            var prefix = (country === "msk") ? "" : country + "_"

            //obory
            if(this.$store.state.data.fields.length === 0) this.getApiFields()
            //příběhy
            if(this.$store.state.data.stories.length === 0) this.getApiStories(prefix)
            //inzeráty
            if(this.$store.state.data.jobs.length === 0) this.getApiJobs(prefix)
            //kategorie inzerátů
            if(this.$store.state.data.jobs.length === 0) this.getApiJobsCategories()
            //options (mikro data bokem)
            if(this.$store.state.data.options.length === 0) this.getApiOptions(prefix)
            //okresy
            if(this.$store.state.data.districts.length === 0) this.getApiDistricts(prefix)
            //firmy
            if(this.$store.state.data.companies.length === 0) this.getApiCompanies(prefix)
            //smlouva
            if(this.$store.state.data.contract.length === 0) this.getApiContract()
            //obecné otázky k pohovoru
            if(this.$store.state.data.interview.length === 0) this.getApiInterview()
        },
    },
}
