<template>

    <transition name="fade" appear>

        <div>
            <main
                class="bg-no-repeat bg-green-lighter md:bg-transparent bg-top lg:min-h-[1000px] pb-20 lg:pb-0"
                :class="[
                    {'bg-introHome' : screen === 'countries'},
                    {'bg-introHome ' : screen === 'hp'},
                    {'bg-introHome2' : screen === 'parts'},
                    {'bg-introHome3' : screen === 'popups'},
                ]"
            >
                <div class="container">

                    <transition
                        enter-from-class="-translate-y-24 opacity-0"
                        enter-to-class="translate-y-0 opacity-100"
                        enter-active-class="duration-1000 delay-500"
                        leave-from-class="translate-y-0 opacity-100"
                        leave-to-class="-translate-y-24 opacity-0"
                        leave-active-class="duration-1000 "
                    >
                        <div
                            v-if="screen === 'hp'"
                            class="mx-auto max-w-[1070px] absolute"
                        >
                            <div class="px-4 lg:px-6 py-7 lg:inline-block mt-[14px] mb-8 rounded-5 border-[5px] border-[#3F6A7D] relative bg-white">
                                <div class="flex flex-col lg:flex-row items-center justify-between gap-8">
                                    <a href="https://european-union.europa.eu/index_cs">
                                        <img src="@/assets/img/logo-eu.png" alt="EU">
                                    </a>
                                    <a href="https://www.msmt.cz/">
                                        <img src="@/assets/img/logo-msmt.png" alt="MŠMT">
                                    </a>
                                </div>
                                <img class="absolute left-11 top-[-20px]" src="@/assets/img/intro-home-clip.svg" alt="clip">
                                <img class="absolute right-11 top-[-20px]" src="@/assets/img/intro-home-clip.svg" alt="clip">
                            </div>
                        </div>
                    </transition>

                    <div
                        class="text-center mb-8 lg:mb-24 transition-all pt-[270px] lg:pt-44 duration-1000"
                        :class="{'!pt-16 lg:!pt-32' : screen != 'hp'}"
                    >
                        <div v-if="screen === 'countries'">
                            <h1 class="h1 lg:!text-[52px] text-green-dark">Vítej v aplikaci <br>Cesta k zaměstnání!</h1>
                            <p class="h4 mb-4">Vyber si svůj kraj</p>
                        </div>
                        <div v-else>
                            <p class="h4 mb-4">{{screen === 'hp' ? 'Najdi si svou' : (screen === 'parts' ? 'Hraj vybrané části' : 'Tipy pro pracovní život')}}</p>
                            <h1
                                class="h1 lg:!text-[68px] text-green-dark"
                                :class="{'!text-gray-dark lg:!text-green-dark' : screen === 'hp'}"
                            >{{screen === 'hp' ? 'Cestu k zaměstnání' : (screen === 'parts' ? 'Vem to zkratkou' : 'Mohlo by se hodit')}}</h1>
                        </div>
                    </div>

                    <transition name="fade" mode="out-in">

                        <!-- rozcestník -->
                        <div v-if="screen === 'countries'">
                            <div class="text-center">
                                <div>
                                    <buttonPrimary
                                    title="Moravskoslezský kraj"
                                    size="big"
                                    @click="goToCountry('msk')"
                                    class="mb-6 lg:mb-10"
                                />
                                </div>
                                <div>
                                    <buttonPrimary
                                    title="Karlovarský kraj"
                                    size="big"
                                    @click="goToCountry('kvk')"
                                    class="mb-6 lg:mb-10"
                                />
                                </div>
                            </div>
                        </div>

                        <!-- HP -->
                        <div v-else-if="screen === 'hp'">
                            <div class="text-center" :class="$store.state.country == 'msk' ? 'mb-20 lg:mb-32' : ($store.state.country == 'kvk' ? 'mb-24 lg:mb-32' : '')">
                                <buttonPrimary
                                    title="Hrát celou hru"
                                    size="big"
                                    @click="goToProfile()"
                                    class="mb-6 lg:mb-10"
                                />

                                <div class="space-y-6 mb-6 md:mb-8">
                                    <div>
                                        <buttonPrimary
                                            title="Hrát vybrané části"
                                            size="big"
                                            color="blue"
                                            @click="screen = 'parts'"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="Jak hrát"
                                            size="big"
                                            color="blue"
                                            @click="modalVideoOpen = true; playVideo()"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="O aplikaci"
                                            size="big"
                                            color="blue"
                                            @click="modalAboutOpen = true"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="Tipy pro pracovní život"
                                            size="big"
                                            color="blue"
                                            @click="screen = 'popups'"
                                        />
                                    </div>
                                </div>

                                <div
                                    @click="backToCountries()"
                                    class="min-w-[141px] inline-flex gap-2 items-center cursor-pointer relative before:absolute before:block before:left-0 before:right-0 before:-bottom-2 before:h-0.5 before:bg-yellow before:opacity-0 before:invisible hover:before:opacity-100 hover:before:visible before:transition-all select-none"
                                >
                                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25L17 6.75ZM0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989592 6.3033 0.696698C6.01041 0.403805 5.53553 0.403805 5.24264 0.696698L0.469669 5.46967ZM17 5.25L1 5.25L1 6.75L17 6.75L17 5.25Z" fill="#EEB018"/>
                                    </svg>
                                    <span class="label-base">Zpět na výběr kraje</span>
                                </div>
                            </div>

                            <!-- loga -->
                            <div class="max-w-[800px] mx-auto space-y-10">

                                <div v-if="$store.state.country == 'msk'" class="flex flex-col lg:flex-row gap-8 lg:gap-20 justify-center items-center">
                                    <a href="https://www.msk.cz/">
                                        <img src="@/assets/img/logo-msk.png" alt="Moravskoslezský kraj">
                                    </a>
                                    <a href="https://www.msk.cz/temata/projekty_eu/index.html">
                                        <img src="@/assets/img/logo-okap.png" alt="OKAP">
                                    </a>
                                    <a href="https://mspakt.cz/">
                                        <img src="@/assets/img/logo-mspakt.png" alt="Moravskoslezský pakt zaměstnanosti">
                                    </a>
                                </div>

                                <div v-else-if="$store.state.country == 'kvk'" class="flex flex-col lg:flex-row gap-8 lg:gap-12 justify-center items-center">
                                    <a href="https://www.khkkk.cz/">
                                        <img class="w-52 h-auto" src="@/assets/img/kvk_logo_komora.svg" alt="Krajská hospodářská komora Karlovarského kraje">
                                    </a>
                                    <a href="https://www.khkkk.cz/institut/">
                                        <img class="w-52 h-auto" src="@/assets/img/kvk_pakt_logo.svg" alt="Pakt zaměstnanosti Karlovarského kraje">
                                    </a>
                                    <a href="https://www.zijemeregionem.cz/">
                                        <img class="w-52 h-auto" src="@/assets/img/kvk_logo_zijemeregionem.png" alt="Žijeme regionem">
                                    </a>
                                    <a href="https://www.kr-karlovarsky.cz/">
                                        <img class="w-52 h-auto" src="@/assets/img/kvk_logo.svg" alt="Karlovarský kraj">
                                    </a>

                                </div>

                                <p class="text-base leading-normal text-center">
                                    <span v-if="$store.state.country == 'msk'">Tato aplikace byla vytvořena v rámci projektu Odborné, kariérové a polytechnické vzdělávání v MSK II (OKAP II) spolufinancovaného z ESF prostřednictvím Operačního programu Výzkum, vývoj a vzdělávání.</span>
                                    <span v-else-if="$store.state.country == 'kvk'">Aplikace byla vytvořena v rámci projektu Odborné, kariérové a polytechnické vzdělávání v MSK II (OKAP II) spolufinancovaného z ESF prostřednictvím Operačního programu Výzkum, vývoj a vzdělávání a licence pro Karlovarský kraj byla zakoupena v rámci dotace z rozpočtu Karlovarského kraje „Zvýšení kvality vzdělávání intenzivním propojením vzdělávání s trhem práce v Karlovarském kraji.“</span>
                                </p>

                            </div>

                            <!-- info dole -->
                            <div class="py-8">
                                <div class="container italic text-center">
                                    <p class="text-sm mb-0.5">Veškeré postavy a firmy jsou fiktivní.</p>
                                    <p class="text-sm">Aplikace prošla právní korekcí: Mgr. Jan Boroš, advokát, advokátní kancelář Grobelný & Skřipský</p>
                                    <p class="text-sm">
                                        <span @click="$router.push('/pristupnost')" class="cursor-pointer underline hover:no-underline">Prohlášení o přístupnosti</span>
                                    </p>
                                    <p class="text-sm">Vytvořilo <a href="https://www.brytro.cz" class="underline hover:no-underline" rel="nofollow">Brýtro.cz</a></p>
                                </div>
                            </div>

                        </div>

                        <!-- části aplikace -->
                        <div v-else-if="screen === 'parts'">

                            <div class="text-center">
                                <div class="space-y-6 mb-20">
                                    <div>
                                        <buttonPrimary
                                            title="Životopis"
                                            size="big"
                                            @click="goToCv()"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="Hledání práce"
                                            size="big"
                                            @click="goToPortal()"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="Pracovní smlouva"
                                            size="big"
                                            @click="goToContract()"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="Pracovní situace"
                                            size="big"
                                            @click="goToQuiz()"
                                        />
                                    </div>
                                </div>

                                <div
                                    @click="screen = 'hp'"
                                    class="min-w-[141px] inline-flex gap-2 items-center cursor-pointer mb-6 relative before:absolute before:block before:left-0 before:right-0 before:-bottom-2 before:h-0.5 before:bg-yellow before:opacity-0 before:invisible hover:before:opacity-100 hover:before:visible before:transition-all select-none"
                                >
                                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25L17 6.75ZM0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989592 6.3033 0.696698C6.01041 0.403805 5.53553 0.403805 5.24264 0.696698L0.469669 5.46967ZM17 5.25L1 5.25L1 6.75L17 6.75L17 5.25Z" fill="#EEB018"/>
                                    </svg>

                                    <span class="label-base">Vrátit se zpět na úvod</span>
                                </div>
                            </div>

                        </div>

                        <!-- tipy pro pracovní život -->
                        <div v-else-if="screen === 'popups'">
                            <div class="text-center">
                                <div class="space-y-6 mb-20">
                                    <div>
                                        <buttonPrimary
                                            title="Jak vytvořit životopis"
                                            size="big"
                                            color="blue"
                                            @click="modalInfoCvOpen = true"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="Jak hledat práci"
                                            size="big"
                                            color="blue"
                                            @click="modalInfoJobOpen = true"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="Jak se připravit na pohovor"
                                            size="big"
                                            color="blue"
                                            @click="modalInfoInterviewOpen = true"
                                        />
                                    </div>
                                    <div>
                                        <buttonPrimary
                                            title="Jak má vypadat pracovní smlouva"
                                            size="big"
                                            color="blue"
                                            @click="modalInfoContractOpen = true"
                                        />
                                    </div>
                                </div>

                                <div
                                    @click="screen = 'hp'"
                                    class="min-w-[141px] inline-flex gap-2 items-center cursor-pointer mb-6 relative before:absolute before:block before:left-0 before:right-0 before:-bottom-2 before:h-0.5 before:bg-yellow before:opacity-0 before:invisible hover:before:opacity-100 hover:before:visible before:transition-all select-none"
                                >
                                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25L17 6.75ZM0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989592 6.3033 0.696698C6.01041 0.403805 5.53553 0.403805 5.24264 0.696698L0.469669 5.46967ZM17 5.25L1 5.25L1 6.75L17 6.75L17 5.25Z" fill="#EEB018"/>
                                    </svg>

                                    <span class="label-base">Vrátit se zpět na úvod</span>
                                </div>
                            </div>
                        </div>

                    </transition>

                </div>
            </main>

            <modalInfoCv
                :open="modalInfoCvOpen"
                @close="modalInfoCvOpen = false"
                v-bind:files="true"
            />

            <modalInfoJob
                :open="modalInfoJobOpen"
                @close="modalInfoJobOpen = false"
            />

            <modalInfoInterview
                :open="modalInfoInterviewOpen"
                @close="modalInfoInterviewOpen = false"
            />

            <modalInfoContract
                :open="modalInfoContractOpen"
                @close="modalInfoContractOpen = false"
                v-bind:files="true"
            />

            <modalPopup
                :open="modalAboutOpen"
                @close="modalAboutOpen = false"
            >
                <template v-slot:heading>
                    O aplikaci
                </template>
                <template v-slot:body>
                    <div
                        v-if="$store.state.country == 'msk'"
                        class="text-center mb-6"
                    >
                        <a href="/files/letak.pdf">Stáhnout PDF leták (1.9 MB)</a>
                    </div>
                    <p>Cesta k&nbsp;zaměstnání je vzdělávací aplikace, která seznamuje žáky s&nbsp;jednotlivými kroky při hledání zaměstnání. Je určena především pro žáky středních škol, ale může ji využívat kdokoli, kdo se chce jednoduchou a zábavnou formou seznámit s&nbsp;tímto tématem.</p>
                    <p>V&nbsp;rámci aplikace se žáci seznámí s&nbsp;tvorbou životopisu, hledáním práce na pracovním portále, výběrovým řízením, podpisem pracovní smlouvy a základy pracovního práva. Jednotlivé moduly je možné projíždět samostatně v&nbsp;rámci tzv. „mini-her“ nebo jako ucelenou hru. Součástí nástroje jsou také odkazy a tipy pro hledání práce v&nbsp;reálném životě.</p>
                    <p>Časová náročnost celé hry je 1 vyučovací hodina (cca 35-45 min).</p>

                    <p v-if="$store.state.country == 'msk'">Aplikace byla vytvořena v rámci projektu Odborné, kariérové a polytechnické vzdělávání v MSK II (OKAP II) spolufinancovaného z ESF prostřednictvím Operačního programu Výzkum, vývoj a vzdělávání.</p>
                    <p v-else-if="$store.state.country == 'kvk'">Aplikace byla vytvořena v rámci projektu Odborné, kariérové a polytechnické vzdělávání v MSK II (OKAP II) spolufinancovaného z ESF prostřednictvím Operačního programu Výzkum, vývoj a vzdělávání a licence pro Karlovarský kraj byla zakoupena v rámci dotace z rozpočtu Karlovarského kraje „Zvýšení kvality vzdělávání intenzivním propojením vzdělávání s trhem práce v Karlovarském kraji.“</p>

                    <p>Aplikace prošla právní korekcí ze strany Mgr. Jan Boroš, advokát, advokátní kancelář Grobelný & Skřipský. Veškeré postavy a firmy, které se v&nbsp;aplikací objevují, jsou fiktivní.</p>

                    <p v-if="$store.state.country == 'msk'">V&nbsp;případě, že by Vaše škola měla zájem o bezplatnou modelovou hodinu s&nbsp;touto aplikací, tak prosím kontaktujte <a href="mailto:ktvrda@mspakt.cz">ktvrda@mspakt.cz</a>.</p>
                    <p v-else-if="$store.state.country == 'kvk'" class="text-sm">V případě, že by Vaše škola měla zájem o bezplatnou modelovou hodinu s touto aplikací, tak prosím kontaktujte <a href="mailto:volfova@khkkk.cz">volfova@khkkk.cz</a>.</p>
                </template>
            </modalPopup>

            <modalPopup
                :open="modalVideoOpen"
                @close="stopVideo(); modalVideoOpen = false"
            >
                <template v-slot:heading>
                    Jak hrát
                </template>
                <template v-slot:body>
                    <iframe class="!w-full !h-auto aspect-video" width="560" height="315" :src="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <p class="mt-4 text-center">
                        <a href="/files/Manual_k_obsluze-Cesta_k_zamestnani.pdf">Stáhnout PDF manuál (5 MB)</a>
                    </p>
                </template>
            </modalPopup>

        </div>

    </transition>

</template>


<script>

import loadDataMixin from '@/mixins/loadData';
import buttonPrimary from '@/components/buttonPrimary.vue';
import modalInfoCv from '@/components/modalInfoCv.vue';
import modalInfoJob from '@/components/modalInfoJob.vue';
import modalInfoInterview from '@/components/modalInfoInterview.vue';
import modalInfoContract from '@/components/modalInfoContract.vue';
import modalPopup from '@/components/modalPopup.vue';

export default {
    components: {
        buttonPrimary,
        modalInfoCv,
        modalInfoJob,
        modalInfoInterview,
        modalInfoContract,
        modalPopup,
    },

    mixins: [loadDataMixin],

    data() {
        return {
            modalInfoCvOpen: false,
            modalInfoJobOpen: false,
            modalInfoInterviewOpen: false,
            modalInfoContractOpen: false,
            modalAboutOpen: false,
            modalVideoOpen: false,

            videoUrl: "https://www.youtube.com/embed/GPptqyBCALQ?autoplay=0",

            screen: 'countries',
        }
    },

    methods: {
        resetGame() {
            //vymazání store
            this.$store.dispatch("resetState")
        },
        backToCountries() {
            this.$store.commit('UPDATE_COUNTRY', '')
            this.screen = 'countries'
        },
        goToCountry($country) {
            this.$store.commit('UPDATE_COUNTRY', $country)
            this.screen = 'hp'
        },
        goToProfile() {
            //načtení dat
            this.loadAllData(this.$store.state.country)

            //track event
            this.gtagEvent()

            this.$router.push('/profil-uvod')
            this.$store.commit('UPDATE_GENERAL_PROGRESS', 'profile')
        },
        goToCv() {
            //načtení dat
            this.loadAllData(this.$store.state.country)

            this.$router.push('/generovani')
            this.$store.commit('UPDATE_QUICKMODE', true)
            this.$store.commit('UPDATE_GENERAL_PROGRESS', 'cv')
        },
        goToPortal() {
            //načtení dat
            this.loadAllData(this.$store.state.country)

            this.$router.push('/generovani')
            this.$store.commit('UPDATE_QUICKMODE', true)
            this.$store.commit('UPDATE_GENERAL_PROGRESS', 'portal')
        },
        goToContract() {
            //načtení dat
            this.loadAllData(this.$store.state.country)

            this.$router.push('/generovani')
            this.$store.commit('UPDATE_GENERAL_PROGRESS', 'contract')
            this.$store.commit('UPDATE_QUICKMODE', true)
        },
        goToQuiz() {
            //načtení dat
            this.loadAllData(this.$store.state.country)

            this.$router.push('/situace')
            this.$store.commit('UPDATE_GENERAL_PROGRESS', 'quiz')
            this.$store.commit('UPDATE_QUICKMODE', true)
        },

        scrollToTopScreen() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },

        playVideo() {
            this.videoUrl = this.videoUrl.replace('autoplay=0','autoplay=1')
        },
        stopVideo() {
            this.videoUrl = (this.modalVideoOpen) ? this.videoUrl.replace('autoplay=1','autoplay=0') : this.videoUrl
        },

        gtagEvent() {
            // eslint-disable-next-line
            if (typeof gtag === 'function') {

                if(this.$store.state.country == "msk") {
                    // eslint-disable-next-line
                    gtag('event', 'kraj_msk', {
                        'event_category': 'Game',
                        'event_label': 'Kraj MSK',
                        'value': 0
                    });
                } else if(this.$store.state.country == "kvk") {
                    // eslint-disable-next-line
                    gtag('event', 'kraj_kvk', {
                        'event_category': 'Game',
                        'event_label': 'Kraj KVK',
                        'value': 0
                    });
                }

            }
        }
    },

    mounted () {

        //this.$store.commit('UPDATE_COUNTRY', 'msk')


        //reset hry při každém načtení HP
        var country = this.$store.state.country
        this.resetGame()

        if (country) {
            this.$store.commit('UPDATE_COUNTRY', country)
            this.screen = 'hp'
        } else {
            this.screen = 'countries'
        }
    },

    watch: {
        screen(newValue) {
            this.scrollToTopScreen()

            if(newValue != 'hp' && newValue != 'countries') {
                //načtení dat
                this.loadAllData(this.$store.state.country)
            }
        }

    },
}

</script>


<style lang="scss" scoped>

.bg-introHome {
    background-image: url('@/assets/img/bg-home-light.svg');
}
.bg-introHome2 {
    background-image: url('@/assets/img/bg-home-light2.svg');
}
.bg-introHome3 {
    background-image: url('@/assets/img/bg-home-light3.svg');
}

</style>
