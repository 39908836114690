<template>

    <router-view/>

    <div
        v-if="isDev"
        class="fixed bottom-0 left-0 z-50 bg-gray-800 text-white p-1 cursor-pointer"
        @click="clear"
    >Reset aplikace</div>
    <div
        v-if="isDev"
        class="fixed bottom-0 right-0 z-50 bg-gray-800 text-white p-1 cursor-pointer"
        @click="vuexData"
    >Vuex data</div>

</template>


<script>

export default {

    computed: {
        isDev() {
            return (process.env.NODE_ENV === "development") ? true : false
        },
        url() {
            return window.location.pathname
        },
        progress() {
            return this.$store.state.progress.general
        },
    },

    methods: {
        clear() {
            setTimeout(() => {
                this.$store.dispatch("resetState")
            }, 500)
            this.$router.push("/")
        },
        vuexData() {
            this.$store.getters.state
        },
    },

    mounted () {
        //nemožnost vrátit se zpět, krom úvodní obrazovky
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            history.go(1);
        };


        //alert při refreshi na produkci
        /* if (!this.isDev) {
            window.onbeforeunload = function()
            {
                return "POZOR! Při obnovení stránky budou všechna data ztracena. Opravdu to chcete provést?";
            };
        } */

    },

    created() {

        //redirect při načtení stránek, na které ještě nemám mít aktuálně přístup
        var redirect = false

        if(this.url.includes('profil') && this.progress != 'profile') {
            redirect = true
        } else if((this.url.includes('zivotopis') || this.url.includes('zivotopis-uvod')) && this.progress != 'cv' && this.progress != 'portal' && this.progress != 'email' && this.progress != 'employmentDepartment' && this.progress != 'cvQuickModeRecap') {
            redirect = true
        } else if((this.url.includes('portal') || this.url.includes('portal-uvod')) && this.progress != 'portal' && this.progress != 'email') {
            redirect = true
        } else if(this.url.includes('email') && this.progress != 'email' && this.progress != 'employmentDepartmentEmail') {
            redirect = true
        } else if((this.url.includes('pohovor') || this.url.includes('pohovor-uvod')) && this.progress != 'interview') {
            redirect = true
        } else if((this.url.includes('smlouva')  || this.url.includes('smlouva-uvod')) && this.progress != 'contract') {
            redirect = true
        } else if((this.url.includes('situace') || this.url.includes('situace-uvod')) && this.progress != 'quiz') {
            redirect = true
        } else if((this.url.includes('vyhodnoceni') || this.url.includes('vyhodnoceni-uvod')) && this.progress != 'score') {
            redirect = true
        } else if(this.url.includes('dotaznik') && this.progress != 'feedback') {
            redirect = true
        } else if(this.url.includes('spokojenost') && this.progress != 'satisfaction') {
            redirect = true
        } else if(this.url.includes('dekujeme') && this.progress != 'feedback') {
            redirect = true
        } else if(this.url.includes('verejne-prospesne-prace') && this.progress != 'gameOver') {
            redirect = true
        } else if(this.url.includes('urad') && this.progress != 'gameOver' && this.progress != 'employmentDepartment') {
            redirect = true
        }

        if (redirect) {
            this.$router.push('/')
        }

    }

}

</script>


<style lang="scss">

.main {
    @apply pt-12 relative;
}

[v-cloak] {
    @apply hidden;
}


input[type="radio"] {
    @apply appearance-none w-[18px] h-[18px] rounded-full border-2 border-yellow bg-white translate-y-1 cursor-pointer relative ;

    &+label {
        @apply select-none;
    }

    &:checked+label {
        @apply font-semibold;
    }

    &:checked {
        &:before {
            content: '';
            @apply absolute -translate-y-1/2 -translate-x-1/2 left-1/2 top-1/2 w-[9px] h-[9px] z-10 bg-yellow rounded-full shadow-button;
        }
    }
}

.form-label {
    @apply text-lg cursor-pointer pl-4;
}





//global transitions

.fadedown-enter-from {
    @apply opacity-0 invisible -translate-y-5;
}
.fadedown-enter-to,.fadedown-leave-from {
    @apply opacity-100 visible;
}
.fadedown-leave-to {
    @apply opacity-0 invisible translate-y-5;
}
.fadedown-enter-active, .fadedown-leave-active {
    @apply transition-all duration-1000;
}


.fade-enter-from, .fade-leave-to {
    @apply opacity-0 invisible;
}
.fade-enter-to, .fade-leave-from {
    @apply opacity-100 visible;
}
.fade-enter-active, .fade-leave-active {
    @apply transition-all duration-500;
}

//fade pbez leave efektu
.fadeAppear-enter-from{
    @apply opacity-0 invisible;
}
.fadeAppear-enter-to{
    @apply opacity-100 visible;
}
.fadeAppear-enter-active {
    @apply transition-all duration-500;
}






//životopis karty
//styly pro kartu - html definováno v json
.cv-card-label {
    @apply uppercase font-bold font-display text-xs lg:text-base shrink-0;
}
.cv-card-row {
    @apply flex space-x-4 mb-1 last:mb-0;
}
.cv-card-text {
    @apply text-xs lg:text-base leading-paragraph;
}
.cv-card--short {
    .cv-card-label {
        @apply basis-14 lg:basis-20;
    }
}
.cv-card--long {
    .cv-card-label {
        @apply basis-20 lg:basis-36;
    }
    .cv-card-row {
        @apply mb-4 last:mb-0
    }
}
.cv-card--middle {
    .cv-card-label {
        @apply basis-16 lg:basis-28;
    }
    .cv-card-row {
        @apply mb-4 last:mb-0;
    }
}
.cv-card--longer {
    .cv-card-label {
        @apply basis-40 lg:basis-52;
    }
    .cv-card-row {
        @apply mb-4 last:mb-0;
    }
}


//univerzální seznam (využívá se hlavně v modalu)
.entry-content ul {
    @apply list-none;

    li {
        @apply text-lg leading-relaxed pl-8 relative mb-2 last:mb-0
        before:w-1.5 before:h-1.5 before:block before:bg-green before:rounded-full before:absolute before:top-2.5 before:left-2 ml-0;

        ul {
            @apply list-none;

            li {
                @apply first:mt-2;
            }
        }
    }
}


</style>
