<template>

    <modalPopup
        :open="open"
        @close="close()"
    >
        <template v-slot:heading>
            1. Pracovní smlouva
        </template>
        <template v-slot:body>

            <div class="entry-content">

                <p class="text-center -translate-y-6"><a class="underline hover:no-underline" href="/files/pracovni_smlouva.pdf">Vzor pracovní smlouvy ke stažení</a></p>

                <p>Pracovní smlouva je nejrozšířenější právní dokument. Jejím podepsáním by měla začínat každá oficiální práce. Existují však i výjimky, kdy pracovní poměr může někdy vzniknout jmenováním (např. ředitele školy, soudce apod.).</p>
                <p><b>Pracovní smlouvu může uzavřít každý člověk starší 15 let.</b> Nesmí to však být v době, kdy pro něj ještě platí povinná školní docházka. Nezletilí do 18 let mají dle zákona trochu odlišné podmínky oproti zletilým (např. ohledně pracovní doby, těžkých prací apod.).</p>

                <h6 class="h6 text-green-dark mb-2 mt-6">1.1. Povinné náležitoti pracovní smlouvy</h6>
                <p><b>Každá pracovní smlouva musí obsahovat</b> 3 hlavní náležitosti stanovené zákoníkem práce:</p>
                <ul class="list">
                    <li>Druh práce, který má zaměstnanec pro zaměstnavatele vykonávat (např. kuchař, řidič, účetní, právník, lékař apod.).</li>
                    <li>Místo výkonu práce (např. Ostrava).</li>
                    <li>Den nástupu do práce (např. 1. 11. 2021).</li>
                </ul>
                <p>Pokud pracovní smlouva neobsahuje všechny tyto tři povinné náležitosti, je neplatná (a nezáleží na tom, jestli byla uzavřena ústně nebo písemně viz dále).</p>
                <p>Podle zákoníku práce platí povinnost uzavřít pracovní smlouvu písemně. Ale i ústní pracovní smlouva zahajuje pracovní poměr se všemi jeho důsledky. Je však vždy lepší mít písemnou podobu. </p>

                <h6 class="h6 text-green-dark mb-2 mt-6">1.2. Zkušební doba</h6>
                <p>Zkušební doba musí být vždy sjednána písemně a nesmí být delší než <b>3 měsíce</b> nebo <b>6 měsíců u vedoucích zaměstnanců</b>. Je možné ji domluvit nejpozději v den nástupu do práce. </p>
                <p>Zkušební doba nemůže být smluvně dodatečně prodloužena. Vždy platí maximum 3 měsíce (6 měsíců pro vedoucí pracovníky).</p>

                <h6 class="h6 text-green-dark mb-2 mt-6">1.3. Doba určitá a neurčitá</h6>
                <p>Pracovní poměr může být sjednán na dobu určitou nebo na dobu neurčitou.</p>
                <p>Pokud není ve smlouvě uvedena doba trvání pracovního poměru, jde <b>vždy o poměr na dobu neurčitou</b>.</p>
                <p>Pokud jde o <b>poměr na dobu určitou</b>, nesmí ze zákona poměr mezi stejným zaměstnavatelem a stejným zaměstnancem přesáhnout <b>3 roky</b> (může být samozřejmě kratší, obvykle jde o 1 rok). Ode dne vzniku pracovního poměru na dobu určitou může být tento pracovní poměr zopakován nebo prodloužen <b>nanejvýš dvakrát</b>.</p>

                <h6 class="h6 text-green-dark mb-2 mt-6">1.4. Pracovní doba</h6>
                <p>Týdenní pracovní doba u zaměstnance v jednosměnném pracovním režimu může být maximálně <b>40 hodin týdně</b>. Do této doby se nezapočítávají přestávky v práci a práce přesčas. Některé skupiny zaměstnanců mají týdenní pracovní dobu kratší, především kvůli náročnosti práce (např. 37,5 hodin u pracujících v podzemí při těžbě uhlí) nebo při vícesměnných režimech (37,5 hodin u třísměnného nepřetržitého pracovního režimu a 38,75 u dvousměnného režimu). Pracovní dobu rozvrhuje zaměstnavatel obvykle do pětitýdenního pracovního týdne a určí začátek a konec směn. Zaměstnanec je povinen být na začátku směny na svém pracovišti a odcházet z něho až po skončení směny.</p>

                <h6 class="h6 text-green-dark mb-2 mt-6">1.5. Dovolená</h6>
                <ul class="list">
                    <li>Základní výměra dovolené činí nejméně <b>4 týdny</b> v kalendářním roce (při úvazku 40 hodin týdně za celý rok).</li>
                    <li>Státní zaměstnanci mají výměru dovolené <b>5 týdnů</b> v kalendářním roce.</li>
                    <li>Pedagogičtí pracovníci a akademičtí pracovníci vysokých škol mají dovolenou 8 týdnů.</li>
                </ul>

                <h2 class="h5 mt-8 text-green-dark">2. Právní vztahy založené dohodami o pracích konaných mimo pracovní poměr</h2>
                <p>Kromě pracovní smlouvy je možné také uzavřít <b>dohodu o pracovní činnosti</b> a <b>dohodu o provedení práce</b>.</p>

                <p>Dle novely zákoníku práce je zaměstnavatel povinen seznámit zaměstnance pracující na dohody <b>s rozvrhem pracovní doby alespoň 3 dny před začátkem směny nebo obdobím, na které je rozvrh vypracován</b>, pokud se obě strany nedohodnou na jiné době.</p>

                <p>Zaměstnanci mají rovněž <b>právo na placenou dovolenou</b> po splnění určitých podmínek. Výměra dovolené bude určena na základě fiktivní týdenní pracovní doby v délce 20 hodin. Zaměstnanci (dohodáři) tedy vznikne právo na dovolenou až při odpracování 4násobku týdenní pracovní doby = 80 hodin v roce a současně  jeho pracovněprávní vztah  trval v&nbsp;roce nepřetržitě po dobu alespoň 28 kalendářních dní (platné od 1.1.2024).</p>

                <p>Novým nárokem dohodářů bude také <b>volno z důvodu všech přípustných překážek v práci na straně zaměstnance</b> (zapříčiněné např. návštěvou lékaře či účastí na pohřbu), náhrada odměny jim však příslušet nebude, ledaže se zaměstnavatel se zaměstnanec dohodnou jinak. Překážky v práci se ale budou započítávat do odpracované doby pro účely výpočtu dovolené.</p>

                <p>Zaměstnanci pracující na dohodu budou mít také <b>nárok na příplatky za práci ve svátek, za noční práci, za práci ve ztíženém pracovním prostředí a práci o víkendu.</b></p>

                <p>Zaměstnanci pracující na dohodu budou mít nově výslovné <b>právo požádat o zaměstnání v pracovním poměru</b>, na což jim zaměstnavatel bude povinen poskytnout odůvodněnou písemnou odpověď. Platí pro zaměstnance, jehož právní vztahy založené dohodami o provedení práce a dohodami o pracovní činnosti v předchozích 12 měsících v souhrnu u tohoto zaměstnavatele trvaly po dobu nejméně 180 dní.</p>


                <!-- <p>V dohodách o pracích mimo pracovní poměr <b>není zaměstnavatel povinen rozvrhnout zaměstnanci týdenní pracovní dobu</b> ani mu určit počátek a konec směn. Zaměstnanec obvykle rozhoduje sám, kdy činnost nebo úkol splní.</p>
                <p>Zaměstnanci vykonávající činnost na základě dohod o pracích konaných mimo pracovní poměr <b>nemají</b> oproti zaměstnancům v pracovním poměru (na základě pracovní smlouvy) <b>nárok na dovolenou, na odstupné</b> nebo <b>na příplatky za práci přesčas nebo za práci v noci, sobotu či neděli.</b></p> -->


                <p>Výpovědní lhůta u dohod konaných mimo pracovní poměr činí 15 dnů.</p>

                <h6 class="h6 text-green-dark mb-2 mt-6">2.1. Dohoda o provedení práce</h6>
                <ul class="list">
                    <li>musí být uzavřena <b>písemně</b>, jinak je neplatná. Platí zde totéž, co u pracovní smlouvy,</li>
                    <li>ozsah práce nesmí být větší než <b>300 hodin v kalendářním roce (sčítají se všechny uzavřené dohody o provedení práce u daného zaměstnavatele)</b>,</li>
                    <li>může <b>uzavřít každý člověk, kterému bylo 15 let</b>. Nesmí to však být v době, kdy pro něj ještě platí povinná školní docházka,</li>
                    <li>V dohodě o provedení práce <b>musí být uvedena sjednaná práce a doba</b>, na kterou se tato dohoda uzavírá. Dohoda zpravidla obsahuje také místo výkonu práce a sjednanou odměnu.</li>
                </ul>

                <h6 class="h6 text-green-dark mb-2 mt-6">2.2. Dohoda o pracovní činnosti</h6>
                <ul class="list">
                    <li>musí být uzavřena <b>písemně</b>, jinak je neplatná. Platí zde totéž, co u pracovní smlouvy,</li>
                    <li>rozsah práce v průměru nesmí překročit <b>polovinu normální pracovní doby</b> (nejčastěji <b>20 hodin</b> týdně),</li>
                    <li>může <b>uzavřít každý člověk, kterému bylo 15 let</b>. Nesmí to však být v době, kdy pro něj ještě platí povinná školní docházka,</li>
                    <li><b>podstatnou součástí</b> dohody je ujednání o <b>sjednané práci</b>, kterou má zaměstnanec vykonávat, sjednaný <b>rozsah pracovní doby</b> a <b>doba</b>, na kterou se dohoda uzavírá.</li>
                </ul>

            </div>

        </template>
    </modalPopup>

</template>


<script>

import modalPopup from '@/components/modalPopup.vue';

export default {

    components: {
        modalPopup,
    },

    props: {
        open: {
            default: false,
        },
        files: {
            default: false,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },

}

</script>
