<template>

    <modalPopup
        :open="open"
        @close="close()"
    >
        <template v-slot:heading>
            Tipy pro hledání práce v realitě
        </template>
        <template v-slot:body>

             <div class="entry-content">
                <p class="label-lg">Tady máš pár tipů pro hledání práce v reálném světě.</p>

                <h6 class="h6 text-green-dark mb-2">Internetové stránky</h6>
                <p>Zkus třeba <a href="https://jobs.cz">jobs.cz</a> nebo <a href="https://prace.cz">prace.cz</a> - jsou to nejvíce využívané zdroje inzerátů u nás. Řadu nabídek zveřejňuje i Úřad práce. Nebo napiš do vyhledávače „Nabídky práce” a klikej. Všechny zajímavé stránky si ukládej a pravidelně se k nim vracej. Pokud víš jasně, co chceš dělat, hledej přímo hesla jako „Hledáme krotitele tygrů” a „Nabídka práce krotitele tygrů” a podobně. Některé firmy zveřejňují inzeráty jen na svých stránkách v sekci „kariéra“, „volná místa“ apod., takže hledej přímo i na stránkách firem.</p>
                <p>Další pracovní servery: <a href="https://www.volnamista.cz/">www.volnamista.cz</a>, <a href="https://www.cocuma.cz/">www.cocuma.cz</a>, <a href="https://www.profesia.cz/">www.profesia.cz</a>, <a href="https://www.zamestnani.cz/">www.zamestnani.cz</a>, <a href="https://www.dobraprace.cz/">www.dobraprace.cz</a>, <a href="https://nabrigadu.cz/">www.nabrigadu.cz</a>, <a href="https://www.fajn-brigady.cz/">www.fajn-brigady.cz</a>, <a href="https://www.easy-prace.cz/">www.easy-prace.cz</a> apod.</p>

                <h6 class="h6 text-green-dark mb-2">Zkus jiné zdroje – sociální sítě, aplikace</h6>
                <p>Skvělé příležitosti najdeš i na sociální síti zaměřené na práci – <a href="https://www.linkedin.com/">LinkedIn</a>. Můžeš si zde nastavit, že jsi otevřený novým příležitostem a personalisté tě osloví. Nebo můžeš využít i <a href="https://www.facebook.com/">Facebooku</a> či <a href="https://twitter.com/">Twitteru</a>. Také existují různé aplikace, třeba <a href="https://www.pracezarohem.cz/">Práce za rohem</a>.</p>

                <h6 class="h6 text-green-dark mb-2">Využij známostí</h6>
                <p>Víc hlav, víc ví. Platí to i při hledání práce. Využij sociální potenciál bližší i vzdálenější rodiny, přátel, kamarádů a známých. Dej jim vědět, že sháníš nové místo a ideálně přilož i link na LinkedIn či portfolio tvé práce.</p>

                <h6 class="h6 text-green-dark mb-2">Personální agentura</h6>
                <p>Některé firmy nabírají jen přes agentury. Když tě budou mít náboráři v databázi, mohou tě hned oslovit. Přímo na stránkách agentur můžeš najít pozice, které právě obsazují. Známé agentury jsou např. <a href="https://www.grafton.cz/cs">Grafton</a>, <a href="https://www.manpower.cz/">Manpower</a>, <a href="https://www.randstad.cz/">Randstad</a>, <a href="https://www.adecco.cz/">Adecco</a>, <a href="https://www.indexnoslus.cz/">Index Nosluš</a> atd.</p>

                <h6 class="h6 text-green-dark mb-2">Aktivně oslovuj - jdi do toho</h6>
                <p>Pokud tě některá firma láká, nečekej na inzerát. Pošli jim životopis. Nabídni se. Třeba si po přečtení tvého životopisu uvědomí, že právě tebe potřebují.</p>

                <h6 class="h6 text-green-dark mb-2">Veletrhy práce</h6>
                <p>Bývají pořádány veletrhy práce, kde někteří zaměstnavatelé hledají vhodné uchazeče. Zároveň zde můžeš získat cenné informace. Vstupy bývají často zdarma.</p>

                <p class="mt-6">Když budeš váhat, jestli na inzerát odpovědět, podívej se, co o firmě říkají její zaměstnanci na <a href="https://www.atmoskop.cz/">Atmoskop.cz</a>. A taky si vzpomeň, že pohovor není jen od toho, aby si oni rozmysleli, jestli chtějí tebe. Tu samou možnost máš i ty.</p>

            </div>

        </template>
    </modalPopup>

</template>


<script>

import modalPopup from '@/components/modalPopup.vue';

export default {

    components: {
        modalPopup,
    },

    props: {
        open: {
            default: false,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },

}

</script>
